import axios from "axios";
import Utils from "../utils/utils";

export const resetSessionVariables = () => {
	let referenceCode = localStorage.getItem("referenceCode");
	// Handle the response from the server
	localStorage.setItem("isAuthenticated", "");
	localStorage.setItem("patientDetails", "");
	localStorage.setItem("authToken", null);
	window.location.href = "/home/" + referenceCode;
}

const LogOutService = {
	logOut: function () {
		const authToken = localStorage.getItem("authToken");
		return axios.delete(`${Utils.PRIISM_APPT_API_ENDPOINT}/logout`,
			{
				headers: {
					Authorization: `Bearer ${authToken}`,
					'Content-Type': 'application/json', // Set the appropriate content type for your request
				},
			}
		).then((res) => {
			resetSessionVariables();
		}).catch((error) => {
			// Handle errors, if any
			if (["Token has expired", "Signature verification failed"].includes(error?.response?.data?.msg)) {
				localStorage.setItem("isUnAuthorized", JSON.stringify(true));
				resetSessionVariables();
			}
			else {
				console.log(error.response.data)
			}
		});
	},
};

export default LogOutService;
